.working {
  background-color: #e8e9ea;
}

.working-first-half {
  background: linear-gradient(90deg, #e8e9ea 0%, #e8e9ea 50%, #fff 50%, #fff 100%);
}

.working-second-half {
  background: linear-gradient(90deg, #fff 0%, #fff 50%, #e8e9ea 50%, #e8e9ea 100%);
}

.current {
  border: 2px solid #28a745 !important;
  position: relative;
}

.table tr:not(:last-child) td.current {
  border-bottom: 1px solid #dee2e6 !important;
}

.table tr:not(:first-child) td.current {
  border-top: none !important;
}

.hour {
  font-size: 1.1rem;
}

.minute {
  font-size: 0.5rem;
}

.hour-modifier {
  font-size: 0.6rem;
}

.spacer {
  height: 1rem;
}

.text-xs {
  font-size: 0.75rem;
}
